@import url('https://fonts.cdnfonts.com/css/lemon-tuesday');
.construction-container{
    height: 90vh;
    width: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 100px;

    background-color: #ffffff;
}
.construction-logo{
    height: 100px;
    margin-bottom: 75px;
}
.construction-text-container {
    position: relative;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    padding-left: 24px;
    font-family: 'Lemon Tuesday' !important;
    font-size: 28px;
    text-align: center;
    white-space: pre-wrap;
    overflow: hidden;

    max-width: 700px;
}

.construction-text::after {
    content: '|';
    position: absolute;
    right: 0;
    animation: blink 0.7s infinite;
}

.construction-text-container::before,
.construction-text-container::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 40px;
}
.construction-text::before,
.construction-text::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 40px;
}
.email-container{
    display: flex;
    margin-top: 20px;
    align-items: center;
}
.email-text{
    margin-right: 20px;
}
.email-input-container{
    display:flex;
    border-radius: 50px;
    border: 1px solid #f23e87;
    overflow: hidden;
}
.email-input:focus{
    outline: none;
    width: 250px;
}
.email-input::placeholder{
    color: #bebebe;
    font-size: 10px;
}
.email-input{
    height: 28px;
    width: 175px;

    padding: 10px 12px;
    border-radius: 6px 0 0 6px;
    transition: .4s ease-in-out all;
}
.email-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 0 6px 6px 0;
}
.email-btn:hover{
    cursor: pointer;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}