body {
  margin: 0;

  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  width: calc(100%);

  margin-top: 50px;
  padding: 0 50px;
}
.section-title{
  font-size: 30px;
  font-family: 'Playfair Display';
  font-weight: 700;
  margin-bottom: 25px;
  letter-spacing: 1px;
}