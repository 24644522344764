.sponsored-banner{
    height: 150px;
    width: calc(100%);
    background: #efefef;
}
.review-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.review-title{
    font-family: 'Playfair Display';
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 1px;
}
.review-header-btn{
    background: #2c2c2c;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 6px 16px;
    letter-spacing: 1px;
}
.review-header-btn:hover{
    cursor: pointer;
}
.review-images-container{
    height: 450px;
    width: calc(100%);
    background: #efefef;
}
.review-content-splitter{
    display: flex;
}
.review-content{
    flex: 1;
    padding-right: 25px;
}
.review-amenities{
    margin-bottom: 50px;
}
.review-amenities-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
    letter-spacing: 1px;
}
.review-amenities-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 60%;
}
.ammenity{
    flex: 0 0 33.3333%;

    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    letter-spacing: 1px;
}

.review-side-content{
    width: 300px;
}
.review-location-title{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    letter-spacing: 1px;
}
.review-location-map{
    width: calc(100%);
    height: 200px;
    background: #efefef;
}
.review-contact-title{
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 12px;
}
.review-contact-item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.review-contact-text{
    font-weight: bold;
    font-size: 14px;
    margin-left: 8px;
    
}