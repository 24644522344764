.footer-wrapper{
    width: calc(100%);
    padding: 0px 50px;
    margin-top: 50px;

    background: #fff;


    display: flex;
    flex-direction: column;
}
.footer-logo{
    height: 60px;
    
}
.footer-top{
    width: calc(100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    border-bottom: 1px solid #D8D8D8;
}
.footer-input-container{
    display: flex;
    align-items: center;
}
.footer-input{
    background: transparent;
    padding: 6px 12px;
    border: 1px solid #fff;
    border-radius: 20px;

    outline: none;
    color: #fff;
}
.footer-input-btn{
    padding: 6px 12px;
    background: #fff;
    border-radius: 20px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
}
.footer-input-btn:hover {
    cursor: pointer;
}

.footer-mid{
    display: flex;
    justify-content: space-between;
    padding: 20px 0; 
}
.footer-option{
    color: #989898;
    margin: 10px 0;
    font-size: 14px;
    font-weight: bold;
}
.footer-socials{
    display: flex;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.footer-bottom-text{
    color: #2c2c2c;
    margin: 10px 0;
    font-size: 12px;
}