@media only screen and (max-width: 480px) {
    .nav-wrapper{
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 0 !important;
        background: white;
        
    }
    .nav-logo{
        height: 85px;
    }
    .nav-logo-alt{
        height: 60px;
        left: 80px !important;
    }
    
    .construction-container{
        padding: 0 18px;
        height: 100% !important;
        margin-bottom: 20px;
    }
    .construction-logo{
        height: 70px !important;
    }
    .construction-text-container{
        font-size: 22px !important;
        max-width: 400px;
        padding-top: 0 !important; 
    }

    .email-container{
        flex-direction: column;
        justify-content: center;
        align-content: center;
        
    }
    .email-text{
        margin-bottom: 15px;
        margin-right: 0 !important;
    }
    .email-input{
        height: 40px;
    }
    .email-btn{
        height: 40px;
        width: 40px;
    }
    .email-input:focus {
        outline: none;
        width: 200px;
    }

    .footer-wrapper{
        margin-top: 150px !important;
    }
    .footer-logo{
        left: 50%;
        top: 20%;
        transform: translate(-50%, -100%);
    }
}