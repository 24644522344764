.directory-landing{
    height: 80vh;
    width: calc(100%);
    display: flex;
}
.directory-landing-content{
    height: calc(100%);
    flex: 1;
    /* background: #2c2c2c; */
    position: relative;
    padding: 0 60px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.directory-landing-title{
    font-family: 'Playfair Display';
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px;
    line-height: 65px;
}
.directory-landing-subtitle{
    font-size: 16px;
    font-weight: bold;
    color: #efefef;
    letter-spacing: 1px; 
    margin-top: 12px
}
.directory-landing-options{
    background: #fff;
    margin-top: 20px;
    display: flex;
    padding: 12px 10px;
    width: 500px;
}
.directory-landing-option{
    height: calc(100%);
    width: 200px;
    height: 40px;
    background: rgba(0, 0, 0, .05);
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;
    color: #D8D8D8;
    padding: 0 12px;
    letter-spacing: 1px;
}
.directory-landing-option:hover{
    cursor: pointer;
}
.directory-landing-plane{
    width: 700px;
    position: absolute;
    bottom: 0;
    right: -200px;
}
.directory-landing-image{
    height: calc(100%);
    width: 70vh;
    background-size: cover;
}
.directory-categories{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.directory-categories-wrapper{
    background: #FAF9F7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
.option{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 40px;
}
.option:hover{
    cursor: pointer;
}
.option-icon{
    font-size: 24px;
}
.option-category{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 8px
}

.directory-destinations-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100%);
    padding: 0 50px;
}
.destination-item{
    margin: 10px;
}
.destination-image{
    height: 200px;
    width: 280px;
    background: #D8D8D8;
    position: relative;
}
.destinations-item-category{
    position: absolute;
    background: #2c2c2c;
    font-size: 16px;
    font-weight: 500;
    color:#fff;
    padding: 10px 16px;
    bottom: -20px;
    right: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.destination-info{
    margin-top: 25px;
}
.destination-title{
    font-family: 'Playfair Display';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}
.destination-subtitle{
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 5px;
    padding-left: 5px;

    border-left: 1px solid #2c2c2c;
}

.directory-ad-space{
    width: calc(100%);
    height: 400px;
    margin: 100px 0;
    background: #D8D8D8;
    position: relative;
}
.directory-ad-sub{
    width: 900px;
    background: #2c2c2c;
    position: absolute;
    bottom: -75px;
    left: 50%;
    transform: translateX(-50%);

    padding: 25px 35px;

    display: flex;
    align-items: center;
}
.directory-email-icon{
    height: 75px
}
.directory-email-text{
    font-family: 'Playfair Display';
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px;
    margin-left: 50px;
}
.directory-email-input-container{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    height: calc(100%);
}
.directory-email-input-wrapper{
    width: 300px;
    background: #fff;
    padding: 8px 10px;
    display: flex;
}
.directory-email-input{
    flex: 1;
    border-right: 2px solid #2c2c2c;
}
.directory-email-input:focus{
    outline: none;
}
.directory-email-input::placeholder{
    color: #bebebe;
}
.directory-email-input-icon{
    background: #2c2c2c;
    padding: 8px;
    margin-left: 8px;
}