.landing-section{
    height: 80vh;
    width: calc(100%);
    position: relative;
}
.landing-overlay {
    height: 100%;
    width: 100%;
    position: absolute;

    background: rgba(0, 0, 0, 0.4);

    display: flex;
    align-items: center;
    padding: 0 50px;
}
.landing-text{
    font-family: 'Playfair Display';
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    z-index: 5;
}

.home-about-container{
    width: calc(100%);
    padding: 50px;
    display: flex;
    flex-direction: column;
    background-color: #FAF9F7;
}
.home-about-title{
    font-family: 'Playfair Display';
    font-size: 30px;
    font-weight: 700;

    margin-right: 75px;
    letter-spacing: 1px;
}
.home-about-description{
    flex: 1;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 1px;
}
.home-about-options{
    width: calc(100%);
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.home-about-option{
    border-bottom: 1px solid #2c2c2c;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
}
.home-about-option:hover{
    cursor: pointer;
}

.destinations-container{
    display: flex;
    justify-content: space-between;
    max-width: calc(100%);
}
.destination{
    height: 250px;
    width: 400px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.destination:hover .destination-cover{
    background: rgba(0, 0, 0, 1);
}
.destination-cover{
    height: 175px;
    width: 300px;
    background: rgba(0, 0, 0, .8);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .3s ease-in-out all;
}
.destination-name{
    color: #fff;
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 26px;
}

.articles{
    width: 100%;
    display: flex;
}
.articles-highlight{
    height: 450px;
    min-width: 700px;
    margin-right: 25px;
}
.article-highlight-overlay{
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, .8) 20%, rgba(0, 0, 0, .4) 40%);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px 18px;
}
.article-highlight-title{
    color: #fff;
    font-family: 'NoirPro';
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.article-highlight-description{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 35px;
    letter-spacing: 1px;
}
.article-highlight-author{
    color: #fff;
    font-size: 12px;
    font-weight: 700;

    width: 100%;
    text-align: end;
}
.articles-list-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.article-list-item{
    width: 100%;
    display: flex;
}
.article-item-image{
    min-width: 120px;
    height: 120px;
    margin-right: 10px;
}
.article-item-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.article-item-title{
    font-family: 'NoirPro';
    font-size: 18px;
    font-weight: 700;
    color: #2c2c2c;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.article-item-description{
    font-size: 12px;
    color: #2e2e2e;
    
    letter-spacing: 1px;
}
.article-item-author{
    width: 100%;
    text-align: right;
    color: #2c2c2c;
    font-size: 12px;
}

.events-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.event-item{
    flex: 1;
    margin-right: 25px;
}
.event-image{
    height: 175px;
    background: #efefef;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    position: relative;
}
.event-ind{
    background: #2c2c2c;
    /* background: linear-gradient(to right, rgb(237, 34, 36), rgb(243, 91, 34), rgb(249, 150, 33), rgb(245, 193, 30), rgb(241, 235, 27) 27%, rgb(241, 235, 27), rgb(241, 235, 27) 33%, rgb(99, 199, 32), rgb(12, 155, 73), rgb(33, 135, 141), rgb(57, 84, 165), rgb(97, 55, 155), rgb(147, 40, 142)); */
    color: #fff;
    font-weight: bold;
    font-size: 12px;

    padding: 4px 12px;
    border-radius: 25px;

    position: absolute;
    top: 10px;
    left: 10px;

    display: flex;
    align-items: center;
}
.lgbtq-ind{
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin-left: 6px;
    background: linear-gradient(to right, rgb(237, 34, 36), rgb(243, 91, 34), rgb(249, 150, 33), rgb(245, 193, 30), rgb(241, 235, 27) 27%, rgb(241, 235, 27), rgb(241, 235, 27) 33%, rgb(99, 199, 32), rgb(12, 155, 73), rgb(33, 135, 141), rgb(57, 84, 165), rgb(97, 55, 155), rgb(147, 40, 142));
}
.event-date-container{
    height: 65px;
    width: 70px;
    background :#2c2c2c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.event-date-day{
    color: #fff;
    font-weight: 900;
    font-size: 20px;
}
.event-date-month{
    color: #fff;
    font-weight: 900;
    font-size: 12px;
}
.event-title{
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    letter-spacing: 1px;
}
.event-descr{
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 180px;

    letter-spacing: 1px;
}