.landing-section {
    height: 80vh;
    width: calc(100%);
    position: relative;
}
.country-intro{
    display: flex;
    flex-direction: column;
    width: calc(100%);
    background: #FAF9F7;
    justify-content: center;
    align-items: center;
    padding: 75px 0;
}
.country-intro-title{
    font-family: 'Playfair Display';
    font-size: 60px;
    font-weight: bold;
}
.country-intro-body{
    font-family: 'Lato';
    font-size: 14px;
    text-align: center;
    max-width: 70%;

    margin-top: 10px;
    letter-spacing: 1px;
    line-height: 20px;
}
.country-section-header{
    font-family: 'Playfair Display';
    font-size: 24px;
    font-weight: bold;
    padding: 0 50px;
    margin-top: 75px;
    margin-bottom: 25px;
    letter-spacing: 1px;
}
.country-section-header.alt{
    margin: 0;
    color: #fff;
}

.country-blogs{
    display: flex;
    padding: 0 50px;
    justify-content: space-between;

}
.country-blog{
    display: flex;
}
.blog-image{
    height: 200px;
    width: 250px;
}
.blog-info{
    padding-left: 20px;
    width: 100%;
    letter-spacing: 1px;
}
.blog-info-title{
    font-family: 'Playfair Display';
    font-size: 18px;
    color: #2c2c2c;
}
.blog-info-body{
    font-size: 14px;
    font-weight: bold;
    color: #929292;
    flex: 1;
    margin-top: 15px;

    max-width: 90%;

    line-height: 20px;
    letter-spacing: 1px;
}
.blog-info-author{
    font-size: 12px;
    color: #2c2c2c;
    margin-top: 25px;
}
.blog-info-author span{
    color: #2c2c2c;
}

.country-hotels{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-right: 100px;
    margin-left: 10px;

    overflow: hidden;
}
.hotels-track{
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
}
.hotel-item{
    flex: 1 0 25%;
    margin-right: 25px;
}
.hotel-image{
    height: 175px;
    width: calc(100%);
    
}
.hotel-title{
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    letter-spacing: 1px;
}
.hotel-location{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.hotel-address{
    padding-left: 5px;
    margin-left: 5px;

    border-left: 1px solid #2c2c2c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
}
.hotel-descr{
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 180px;

    letter-spacing: 1px;
}
.hotel-btn{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    padding: 10px 0;

    background: #2c2c2c;
    margin-top: 50px;
}
.carousel-arrow {
    background-color: #2c2c2c;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    z-index: 2;
}

.carousel-arrow.left {
    margin-right: 15px;
}

.carousel-arrow.right {
    margin-left: 20px;
}

.country-experiences-container{
    background: #2c2c2c;
    padding: 25px 0;
    margin-top: 50px;
}
.exp-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100%);
    height: calc(100%)
}
.exp-image-wrapper{
    height: 400px;
    margin-left: 50px;
    padding: 20px 0;
    padding-right: 50px;
}
.exp-image{
    height: calc(100%);
    width: calc(100%);
}

.curaçao-attractions-menu {
    width: 100%;
    max-width: 700px;
    padding-left: 50px;
    height: 100%;
}

.attraction-item {
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
    transition: max-height 0.3s ease-out;
    max-height: 60px;
    border-bottom: 1px solid #929292;
}

.attraction-item.active {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}

.attraction-header {
    padding: 18px 0;
    font-size: 18px;
    font-weight: bolder;
    color: #fff;
}
.attraction-body{
    font-size: 14px;
    color: #fff;
    padding-bottom: 20px;
    letter-spacing: 1px;
}
