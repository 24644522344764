@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
*{
  margin: 0;
  border: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'Bodoni';
  src: url('./fonts/Bodoni-Bold.ttf');
}


.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.5s ease-out, fadeOut 0.5s ease-in 2.5s forwards;
  z-index: 9999;
}

.toast-icon {
  margin-right: 10px;
  font-size: 20px;
}

.toast-message {
  font-size: 16px;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}