.nav-wrapper{
    width: calc(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    padding: 12px 50px;

    position: fixed;
    z-index: 100;
    background: #fff;
}
.nav-logo{
    height: 60px;
    opacity: 1;
    transition: opacity .4s;
    z-index: 5;
}
.nav-items-container{
    display: flex;
}
.nav-item{
    text-decoration: none;
    color: #2c2c2c;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0 25px;
}
.nav-options-container{
    display: flex;
    align-items: center;
}
.nav-option:hover{
    cursor: pointer;
}